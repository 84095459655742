:root {
    --background-color: hsl(225, 50%, 5%);
    --text-color: hsl(0, 0%, 100%);
}

* {
    color: var(--text-color);
    text-shadow: 0px 2px 5px black;
    font-family: 'Noto Sans Mono', monospace;
}

#root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

body {
    background: var(--background-color);
}

hr { 
    width: 20em; 
}

a { 
    text-decoration: none;
    margin: 0;
    display: inline-block;
    z-index: 1;
}
