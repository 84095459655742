@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Comic+Neue:wght@400;700&family=Bebas+Neue&family=Lilita+One&family=Permanent+Marker&display=swap');

:root {
    --card-height: 180px;
    --card-width: 300px;

    --base-color: hsl(200, 10%, 30%);
    --base-color2: hsl(100, 10%, 10%);

    --card-back-color: hsl(0, 0%, 6.9%);
    --card-back-color2: hsl(0, 0%, 15%);
    --card-border-color: hsl(0, 0%, 69%);
    --border-color: hsl(0, 0%, 0%);

    --card-padding: 3px;
}

.value-card {
    display: flex;
    flex-direction: column; 
    height: var(--card-height);
    width: var(--card-width);
    
    background: radial-gradient(var(--card-back-color2), var(--card-back-color2), var(--card-back-color));
    border: calc(var(--card-padding) + 1px) solid var(--card-border-color);
    border-radius: calc(var(--card-padding) * 4);
    box-sizing: border-box;
    padding: calc(var(--card-padding) + 1px);
    overflow: hidden;
    margin: var(--card-padding);
    position: relative;
    z-index: 3;
}

.card-title, .card-image-frame, .card-stats {
    box-sizing: border-box;
    border-radius: calc(var(--card-padding) * 2);
    text-wrap: nowrap;;
}

.card-title, .card-image-frame {
    border: calc(var(--card-padding) + 1px) solid var(--border-color);
}

.card-stats {
    border: var(--card-padding) solid var(--border-color);
}

.card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(var(--card-height) * 0.25 - var(--card-padding));
    margin: 0px 0px var(--card-padding) 0px;
    background: linear-gradient(175deg, var(--base-color), var(--base-color2));
}

.title-text {
    font: 2em "Bebas Neue", sans-serif;;
    text-shadow: 0px var(--card-padding) 5px black;
    margin: var(--card-padding) 0px 0px 0px;
}

.card-main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.card-image-frame {
    background: linear-gradient(160deg, var(--base-color), var(--base-color2), var(--base-color2));
    height: calc(calc(var(--card-height) * 0.75)  - calc(var(--card-padding) * 4) - 4px);
    width: calc(calc(var(--card-height) * 0.75)  - calc(var(--card-padding) * 4) - 4px);
    margin-right: var(--card-padding);
}

.card-image {
    height: 100%;
}

.card-stats {
    border-spacing: 0px;
    font-size: 0.9em;
    height: calc(calc(var(--card-height) * 0.75)  - calc(var(--card-padding) * 4) - 4px);
    width: 100%;
    background: var(--border-color);
}

.card-stats tr td {
    background: linear-gradient(170deg, var(--base-color), var(--base-color2), var(--base-color2));
    border: calc(var(--card-padding) - 1px) solid var(--border-color);
    border-radius: 4px;
    vertical-align: text-top;
    font: 1em "Comic Neue";
    font-weight: 700;
    text-shadow: var(--card-padding) var(--card-padding) 5px black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
    padding: 1px 3px 1px 3px;
}

.text-color0 { color: rgb(150, 150, 150); }
.text-color1 { color: rgb(255, 0, 0); }
.text-color2 { color: rgb(255, 125, 0); }
.text-color3 { color: rgb(255, 255, 0); }
.text-color4 { color: rgb(125, 255, 0); }
.text-color5 { color: rgb(0, 255, 255); }
.text-color6 { color: rgb(255, 0, 200); }
