@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.topbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 0, 0, 0);
    
    position: fixed;
    z-index: 5;
    left: 50%;
    transform: translate(-50%);
}

.topbar {
    background-image: linear-gradient(rgb(70, 70, 70), rgb(30, 30, 30));
    box-shadow: 0px 5px 20px 5px black;
    height: 60px;
    padding: 10px;
    border-radius: 100px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.topbar-title {
    margin: 16px;
    font: 36px "Lilita One", sans-serif;
    text-wrap: nowrap;
}

.discord-button {
    background-image: linear-gradient(#5865f2, #5865f2, #2f3680);
    box-shadow: 0px 0px 10px #2f3680;
    height: 100%;
    border-radius: 100px;
    border: none;
}

.discord-logo {
    height: 40px;
}

.discord-button:hover { background-image: linear-gradient(#7983ee, #7983ee, #3e48b4); }
.discord-button:active { background-image: linear-gradient(#3b4af0, #3b4af0, #151a52); }

.calculate-button {
    background-image: linear-gradient(#58f260, #58f260, #1fe729);
    box-shadow: 0px 0px 10px rgb(5, 196, 46);
    height: 100%;
    border-radius: 100px;
    border: none;
    padding: 0px 16px 4px 16px;
    font: 20px "Ubuntu", sans-serif;
    font-weight: 700;
    line-height: 20px;
}

.calculate-button:hover { background-image: linear-gradient(#7fec84, #7fec84, #43ee4c); }
.calculate-button:active { background-image: linear-gradient(#2bdf34, #2bdf34, #158a1b); }
