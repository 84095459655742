.popup-overlay {
    position: fixed;
    top: 125%;
    left: 0;
    width: 100%;
    height: 500%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 5px 20px 20px black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.popup-title {
    position: absolute;
    top: 0px;
    left: 15px;
    text-shadow: 0px 0px 10px white;
}

.popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background: linear-gradient(red, red, rgb(200 , 0, 0));
    border-radius: 25%;
}

.popup-body {
    position: absolute;
    bottom: 0;
    margin-bottom: 3%;
    width: 95%;
    height: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

